import { useContext } from "react";
import OrderContext from "../OrderContext";

export default function useProduct() {
  const [order, setOrder] = useContext(OrderContext);

  function addToOrder(orderedProduct) {
    setOrder([...order, orderedProduct]);
  }

  function removeFromOrder(index) {
    setOrder([...order.slice(0, index), ...order.slice(index + 1)]);
  }

  return {
    order,
    addToOrder,
    removeFromOrder,
    setOrder,
  };
}
