import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ShoppingBagIcon } from "@heroicons/react/outline";
import { countFn } from "../Count";
import useProduct from "../utils/useProduct";

const navigation = {
  categories: [
    {
      name: "Женские",
    },
    {
      name: "Мужские",
    },
    {
      name: "Детские",
    },
  ],
  other: [
    { name: "Главная", href: "/" },
    { name: "Контакты", href: "/contact" },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StoreNav() {
  const { womans, mans, kids } = useStaticQuery(graphql`
    query {
      womans: allSanityWoman {
        nodes {
          id
          name
          image {
            asset {
              gatsbyImageData(width: 500)
            }
          }
          category {
            slug {
              current
            }
            name
            id
          }
        }
      }
      mans: allSanityMan {
        nodes {
          id
          name
          image {
            asset {
              gatsbyImageData(width: 500)
            }
          }
          category {
            slug {
              current
            }
            name
            id
          }
        }
      }
      kids: allSanityChildren {
        nodes {
          id
          name
          image {
            asset {
              gatsbyImageData(width: 500)
            }
          }
          category {
            slug {
              current
            }
            name
            id
          }
        }
      }
    }
  `);

  const { order } = useProduct();

  const womansWithCounts = countFn(womans.nodes);
  const mansWithCounts = countFn(mans.nodes);
  const kidsWithCounts = countFn(kids.nodes);

  return (
    <header className="relative bg-white">
      <nav aria-label="Top" className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="border-b border-gray-200 px-4 pb-14 sm:px-0 sm:pb-0">
          <div className="h-16 flex items-center justify-between">
            {/* Logo */}
            <div className="flex-1 flex">
              <Link
                to="/"
                className="font-black bg-yellow-500 hover:bg-yellow-600 text-white px-2 text-xl"
              >
                IMKON
              </Link>
            </div>

            {/* Flyout menus */}
            <Popover.Group className="absolute bottom-0 inset-x-0 sm:static sm:flex-1 sm:self-stretch">
              <div className="border-t h-14 px-4 flex space-x-8 overflow-x-auto pb-px sm:h-full sm:border-t-0 sm:justify-center sm:overflow-visible sm:pb-0">
                <Popover className="flex">
                  {({ open }) => (
                    <>
                      <div className="relative flex">
                        <Popover.Button
                          className={classNames(
                            open
                              ? "border-green-600 text-green-600"
                              : "border-transparent text-gray-700 hover:text-gray-800",
                            "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px uppercase"
                          )}
                        >
                          Женские
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Popover.Panel className="absolute z-10 top-full inset-x-0 text-gray-500 sm:text-sm">
                          {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                          <div
                            className="absolute inset-0 top-1/2 bg-white shadow"
                            aria-hidden="true"
                          />

                          <div className="relative bg-white">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                              <div className="grid grid-cols-1 items-start gap-y-10 gap-x-6 pt-10 pb-12 md:grid-cols-2 lg:gap-x-8">
                                <div className="grid grid-cols-1 gap-y-10 gap-x-6 lg:gap-x-8">
                                  <div>
                                    <p
                                      id="clothing-heading"
                                      className="font-medium text-gray-900 uppercase text-sm"
                                    >
                                      Женская одежда
                                    </p>
                                    <div className="mt-4 border-t border-gray-200 pt-6 sm:grid sm:grid-cols-2 sm:gap-x-6">
                                      <ul
                                        role="list"
                                        aria-labelledby="clothing-heading"
                                        className="space-y-6 sm:space-y-4"
                                      >
                                        <li className="flex text-sm">
                                          <Link
                                            to="/woman"
                                            className="hover:text-gray-800"
                                          >
                                            <span>Все</span>
                                            <span className="ml-1">
                                              {womans.nodes.length}
                                            </span>
                                          </Link>
                                        </li>
                                        {womansWithCounts.map((product) => (
                                          <li
                                            key={product.id}
                                            className="flex text-sm"
                                          >
                                            <Link
                                              to={`/woman/${product.cat.slug.current}`}
                                              className="hover:text-gray-800"
                                            >
                                              <span>{product.name}</span>
                                              <span className="ml-1">
                                                {product.count}
                                              </span>
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Popover className="flex">
                  {({ open }) => (
                    <>
                      <div className="relative flex">
                        <Popover.Button
                          className={classNames(
                            open
                              ? "border-green-600 text-green-600"
                              : "border-transparent text-gray-700 hover:text-gray-800",
                            "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px uppercase"
                          )}
                        >
                          Мужские
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Popover.Panel className="absolute z-10 top-full inset-x-0 text-gray-500 sm:text-sm">
                          {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                          <div
                            className="absolute inset-0 top-1/2 bg-white shadow"
                            aria-hidden="true"
                          />

                          <div className="relative bg-white">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                              <div className="grid grid-cols-1 items-start gap-y-10 gap-x-6 pt-10 pb-12 md:grid-cols-2 lg:gap-x-8">
                                <div className="grid grid-cols-1 gap-y-10 gap-x-6 lg:gap-x-8">
                                  <div>
                                    <p
                                      id="clothing-heading"
                                      className="font-medium text-gray-900 uppercase text-sm"
                                    >
                                      Мужская одежда
                                    </p>
                                    <div className="mt-4 border-t border-gray-200 pt-6 sm:grid sm:grid-cols-2 sm:gap-x-6">
                                      <ul
                                        role="list"
                                        aria-labelledby="clothing-heading"
                                        className="space-y-6 sm:space-y-4"
                                      >
                                        <li className="flex text-sm">
                                          <Link
                                            to="/man"
                                            className="hover:text-gray-800"
                                          >
                                            <span>Все</span>
                                            <span className="ml-1">
                                              {mans.nodes.length}
                                            </span>
                                          </Link>
                                        </li>
                                        {mansWithCounts.map((product) => (
                                          <li
                                            key={product.id}
                                            className="flex text-sm"
                                          >
                                            <Link
                                              to={`/man/${product.cat.slug.current}`}
                                              className="hover:text-gray-800"
                                            >
                                              <span>{product.name}</span>
                                              <span className="ml-1">
                                                {product.count}
                                              </span>
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Popover className="flex">
                  {({ open }) => (
                    <>
                      <div className="relative flex">
                        <Popover.Button
                          className={classNames(
                            open
                              ? "border-green-600 text-green-600"
                              : "border-transparent text-gray-700 hover:text-gray-800",
                            "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px uppercase"
                          )}
                        >
                          Детские
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Popover.Panel className="absolute z-10 top-full inset-x-0 text-gray-500 sm:text-sm">
                          {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                          <div
                            className="absolute inset-0 top-1/2 bg-white shadow"
                            aria-hidden="true"
                          />

                          <div className="relative bg-white">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                              <div className="grid grid-cols-1 items-start gap-y-10 gap-x-6 pt-10 pb-12 md:grid-cols-2 lg:gap-x-8">
                                <div className="grid grid-cols-1 gap-y-10 gap-x-6 lg:gap-x-8">
                                  <div>
                                    <p
                                      id="clothing-heading"
                                      className="font-medium text-gray-900 uppercase text-sm"
                                    >
                                      Детская одежда
                                    </p>
                                    <div className="mt-4 border-t border-gray-200 pt-6 sm:grid sm:grid-cols-2 sm:gap-x-6">
                                      <ul
                                        role="list"
                                        aria-labelledby="clothing-heading"
                                        className="space-y-6 sm:space-y-4"
                                      >
                                        <li className="flex text-sm">
                                          <Link
                                            to="/kids"
                                            className="hover:text-gray-800"
                                          >
                                            <span>Все</span>
                                            <span className="ml-1">
                                              {kids.nodes.length}
                                            </span>
                                          </Link>
                                        </li>
                                        {kidsWithCounts.map((product) => (
                                          <li
                                            key={product.id}
                                            className="flex text-sm"
                                          >
                                            <Link
                                              to={`/kids/${product.cat.slug.current}`}
                                              className="hover:text-gray-800"
                                            >
                                              <span>{product.name}</span>
                                              <span className="ml-1">
                                                {product.count}
                                              </span>
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                {navigation.other.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 uppercase"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </Popover.Group>

            <div className="flex-1 flex items-center justify-end z-10">
              {/* Cart */}
              {order.length > 0 ? (
                <Popover className="ml-4 flow-root text-sm lg:relative lg:ml-8">
                  <Popover.Button className="group -m-2 p-2 flex items-center">
                    <ShoppingBagIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                      {order.length}
                    </span>
                    <span className="sr-only">items in cart, view bag</span>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Panel className="absolute max-h-screen overflow-auto top-16 inset-x-0 mt-px pb-6 bg-white shadow-lg sm:px-2 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:-mr-1.5 lg:w-80 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5">
                      <h2 className="sr-only">Shopping Cart</h2>
                      <form className="max-w-2xl mx-auto px-4">
                        <ul role="list" className="divide-y divide-gray-200">
                          {order.map((singleOrder, index) => {
                            const womanItem = womans.nodes.find(
                              (wom) => wom.id === singleOrder.id
                            );

                            if (womanItem) {
                              return (
                                <li
                                  key={womanItem.id}
                                  className="py-6 flex items-center"
                                >
                                  <GatsbyImage
                                    className="flex-none w-16 h-16 rounded-md border border-gray-200"
                                    image={getImage(womanItem.image.asset)}
                                    alt={womanItem.name}
                                  />
                                  <div className="ml-4 flex-auto">
                                    <h3 className="font-medium text-gray-900">
                                      <Link to="/order">{womanItem.name}</Link>
                                    </h3>
                                    <p className="text-gray-500"></p>
                                  </div>
                                </li>
                              );
                            }
                          })}

                          {order.map((singleOrder, index) => {
                            const manItem = mans.nodes.find(
                              (wom) => wom.id === singleOrder.id
                            );
                            if (manItem) {
                              return (
                                <li
                                  key={manItem.id}
                                  className="py-6 flex items-center"
                                >
                                  <GatsbyImage
                                    className="flex-none w-16 h-16 rounded-md border border-gray-200"
                                    image={getImage(manItem.image.asset)}
                                    alt={manItem.name}
                                  />
                                  <div className="ml-4 flex-auto">
                                    <h3 className="font-medium text-gray-900">
                                      <Link to="/order">{manItem.name}</Link>
                                    </h3>
                                    <p className="text-gray-500"></p>
                                  </div>
                                </li>
                              );
                            }
                          })}

                          {order.map((singleOrder, index) => {
                            const kidsItem = kids.nodes.find(
                              (wom) => wom.id === singleOrder.id
                            );
                            if (kidsItem) {
                              return (
                                <li
                                  key={kidsItem.id}
                                  className="py-6 flex items-center"
                                >
                                  <GatsbyImage
                                    className="flex-none w-16 h-16 rounded-md border border-gray-200"
                                    image={getImage(kidsItem.image.asset)}
                                    alt={kidsItem.name}
                                  />
                                  <div className="ml-4 flex-auto">
                                    <h3 className="font-medium text-gray-900">
                                      <Link to="/order">{kidsItem.name}</Link>
                                    </h3>
                                    <p className="text-gray-500"></p>
                                  </div>
                                </li>
                              );
                            }
                          })}
                        </ul>

                        <Link
                          to="/order"
                          className="block text-center w-full bg-yellow-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                        >
                          Оформить заказ
                        </Link>
                      </form>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ) : (
                <div className="group -m-2 p-2 flex items-center">
                  <ShoppingBagIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                    0
                  </span>
                  <span className="sr-only">items in cart, view bag</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
